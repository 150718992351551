import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { Auth0Service } from '../../auth0-service.service'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {

  //check if the user navigates to the tab
  @HostListener('window:visibilitychange',['$event'])
  handlerFunction(event: any){
    if(!event.srcElement.hidden){
      //update the active group based on the cookie -> doesn't matter if they are logged in or not currently
      this.auth0Service.updateActiveGroup()

      //update the page based on if the user is logged in
      this.ngOnInit()

      // console.log("Page opened. Checking if the user is logged in on a different origin and if they have a refresh token")
      // this.auth0Service.isLoggedIn().then((result) => {
      //   //if the user is logged in but they don't have a refresh token 
      //   if(result === "logged in" && this.auth0Service.currentRefreshToken() == undefined){
      //     //get the authorization code if it is not in the url
      //     const parsedFragment = new URLSearchParams(window.location.href);
      //     const code = parsedFragment.get('code');
      //     console.log(code)

      //     if(code == null){
      //       //refreshes the screen and adds the code to the url
      //       this.auth0Service.getAuthorizationCode()
      //     }
      //   }
      // }).catch((error) => {
      //   console.log("User is not logged in")
      //   //if the session has ended and the user has a refresh token, log them out
      //   if(this.auth0Service.currentRefreshToken() != undefined){
      //     this.auth0Service.logout()
      //   }
      // })
    }
  }

  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;

  showLoginPopup: boolean = false;

  authenticated: boolean;

  userEmail: string;
  userPicture: string;

  constructor(private auth0Service: Auth0Service) {
    this.authenticated = false;
  }

  ngOnInit() {
    //check to see if the user is logged in
    let loginCheckPromise = this.auth0Service.isLoggedIn();
    loginCheckPromise.then((res)=>{
      if(res === "logged in"){
        this.authenticated = true;
        //get the authorization code
        // const parsedFragment = new URLSearchParams(window.location.href);
        // const code = parsedFragment.get('code');
        // console.log(code)
 
        // if(code == null){
        //   this.auth0Service.getAuthorizationCode()
        // }
 
        // this.auth0Service.getRefreshToken(code, () => {
        //   console.log("success callback")
        // }, () => {
        //   console.error('Error fetching refresh token')
        // });
        
        //get the user info
        let userInfoPromise = this.auth0Service.getUserInfo();
        userInfoPromise.then((res)=>{
          if(res !== "Not logged in"){
            this.userEmail = res.email;
            this.userPicture = res.picture;
          }
        }).catch((error)=>{
          console.log("Handling error as we received " + error);
        })
      } else {
        if(this.authenticated){
          this.authenticated = false;
          this.auth0Service.logout()
        }
        
      }
    }).catch((error)=>{
        console.log("Handling error as we received " + error);
        if(this.authenticated){
          this.authenticated = false;
          this.auth0Service.logout()
        }
    })
    
  }

  loginWithRedirect() {
    this.showLoginPopup = !this.showLoginPopup;
  }

  logout() {
    this.auth0Service.logout();
  }
}
